import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalStateService } from '@core/services/global.state.service';
import { UserNameModel } from '@shared/models/user-name.model';
import {
  ConversationApiService,
  TaskApiService,
} from '@core/services/api.services';
import { Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { MsalService } from '@azure/msal-angular';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModel } from '@shared/components/confirmation-dialog/confirmation-dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { MainHeaderStateService } from '@core/components/main-header/main-header.state.service';
import { startWith, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { AccessService } from '@core/services/access.service';
import { PermissionStringEnum } from '@shared/enums';
import { SpinnerService } from '@shared/services/spinner.service';
import { OfferSiteDetailDto } from '@core/services/dto/offer-site-detail.dto';
import { OfferSiteDetailModel } from '@shared/models/offer-site-detail.model';
import { SiteModel } from '@shared/models';
import { AuthService } from '@core/services/auth';

declare const pendo: any;
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  userPendoAuth: UserNameModel;
  userSiteData: SiteModel;
  defaultLocation = '';
  email = '';
  apikey = '010e65dc-ce76-4697-47d6-67e316112fec';
  primaryBranch = '';
  cuName = '';


  private taskUpdateSubscription: Subscription;
  private conversationUpdateSubscription: Subscription;
  userName: UserNameModel;
  activeTasksCounts: number | string;
  activeConversationsCount: number;
  offerSite: OfferSiteDetailModel;
  form = new FormGroup({
    members: new FormControl(),
  });
  notFirstChange = false;
  // permissions
  permissionConversation = PermissionStringEnum.AddConversation;
  permissionConcerns = PermissionStringEnum.ViewConversations;
  permissionViewTasks = PermissionStringEnum.ViewAllTasks;
  permissionAddTasks = PermissionStringEnum.AddTask;
  permissionNotes = PermissionStringEnum.AddNote;
  permissionSales = PermissionStringEnum.CreateSale;
  permissionMembers = PermissionStringEnum.ViewMembers;
  permissionProspects = PermissionStringEnum.ViewProspectProfile;
  permissionManageProspects = false;

  GLOBAL_SETTINGS = GLOBAL_SETTINGS;

  members$ = this.form.get('members').valueChanges.pipe(
    startWith(''),
    switchMap((search) => {
      if (
        typeof search === 'string' &&
        search.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch
      ) {
        this.notFirstChange = true;
        this.service.getSearchedMembers({
          search,
          pageSize: 5,
          sortBy: 'ViewedDate',
          sort: 'desc',
          recentProfilesOnly: false,
          EnableProspectSearch: true,
        });
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.service.members$();
    })
  );
  isOfferOnly = false;
  constructor(
    private globalState: GlobalStateService,
    private taskService: TaskApiService,
    private router: Router,
    private msalService: MsalService,
    private dialog: MatDialog,
    private spinner: SpinnerService,
    private conversationService: ConversationApiService,
    private service: MainHeaderStateService,
    private accessService: AccessService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService
  ) { }


  updatePendoScript(): any {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
        (function (apiKey) {
          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];
              for(w=0,x=v.length;w<x;++w)(function(m){
              o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+'${this.apikey}'+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
        })('${this.apikey}');
      `;
    document.head.appendChild(script);



    pendo.initialize({
      apiKey: this.apikey,
      visitor: {
        id: this.userPendoAuth?.id || '',
        email: this.email || '',
        primary_branch: this.primaryBranch,
        full_name: `${this.userPendoAuth?.firstName || ''} ${this.userPendoAuth?.lastName || ''}`,
      },
      account: {
        id: this.userSiteData?.id,
        base_url: this.userSiteData?.name,
        name: this.cuName
      }
    });
  }

  ngOnInit(): void {
    this.authService.userEmailPendo.subscribe({
      next: (data) => {
        if (data) {
          this.email = data;
        }
      }
    });
    this.authService.getUserNamePromise()
      .then((userData: UserNameModel) => {
        this.userPendoAuth = userData;
      });
    this.authService.getSiteDetails().subscribe({
      next: (data) => {
        this.cuName = data.legalName;
      }
    });
    this.authService.getDefaultLocation().subscribe({
      next: (data) => {
        this.userSiteData = data;
        this.updatePendoScript();
      }
    });
    this.globalState.getBranch();
    this.globalState.userBranches$().pipe(
      take(1)
    ).subscribe({
      next: (data) => {
        const userBranches = data;
        const defaultBranch = userBranches.find(branch => branch.isDefault === true);
        this.primaryBranch = defaultBranch?.label;
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });

    this.userName = this.globalState.user;
    this.globalState.offerVisible$.subscribe(data => {
      this.isOfferOnly = data;
    });

    if (
      this.accessService.checkUserPermissions([
        PermissionStringEnum.ViewAllTasks,
      ])
    ) {
      this.taskService.getTaskUpdateNotifier().subscribe(() => {
        this.updateTaskCounts();
      });
    }
    if (this.accessService.checkUserPermissions([PermissionStringEnum.ManageProspect])) {
      this.permissionManageProspects = true;
    }

    if (
      this.accessService.checkUserPermissions([
        PermissionStringEnum.ViewConversations,
      ])
    ) {
      this.taskService.getConversationUpdateNotifier().subscribe(() => {
        this.updateConversationCounts();
      });
    }
  }

  subscribeToTaskUpdates(): void {
    this.taskUpdateSubscription = this.taskService.getTaskUpdateNotifier().subscribe(() => {
      this.updateTaskCounts();
    });
  }
  subscribeToConversationUpdates(): void {
    this.conversationUpdateSubscription = this.conversationService.getConversationUpdateNotifier().subscribe(() => {
      this.updateConversationCounts();
    });
  }
  ngOnDestroy(): void {
    if (this.taskUpdateSubscription) {
      this.taskUpdateSubscription.unsubscribe();
    }
    if (this.conversationUpdateSubscription) {
      this.conversationUpdateSubscription.unsubscribe();
    }
  }

  updateTaskCounts(): void {
    this.taskService.getTasksSummary(['Opened', 'Reopened']).subscribe((data) => {
      this.activeTasksCounts = data.activeTasksCount;
      this.changeDetectorRef.detectChanges();
    });
  }

  updateConversationCounts(): void {
    this.conversationService
      .getConversationSummary(['Opened'], 'Concern')
      .subscribe((data) => {
        this.activeConversationsCount = data.activeConversationsCount;
      });
  }

  openTasks(): void {
    this.router.navigate([AppConfig.routes.memberManagement.tasks], {
      queryParams: {
        assignedTo: `${this.userName.firstName} ${this.userName.lastName}`,
      },
    });
  }

  openConcerns(): void {
    this.router.navigate([AppConfig.routes.memberManagement.concerns], {
      queryParams: {
        conversationType: 'Concern',
        assignedTo: `${this.userName.firstName} ${this.userName.lastName}`,
      },
    });
  }

  logout(): void {
    this.openDialog();
  }

  openDialog(): void {
    const message = `Are you sure you wish to logout?`;
    const title = `Logout`;

    const dialogData = new ConfirmationDialogModel(title, message, title);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '406px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        localStorage.clear();
        this.msalService.logout();
        this.spinner.show();
      }
    });
  }

  getInitials(): string {
    return this.userName && this.userName.firstName && this.userName.lastName
      ? this.userName.firstName.charAt(0) + this.userName.lastName.charAt(0)
      : '';
  }

  memberClicked(member): void {
    if (member.isProspectInfo) {
      this.router.navigate([`/${AppConfig.routes.prospectProfile.main}`, member.ref],
        { state: { prospectEnabled: member.isProspectInfo } });
    } else {
      this.router.navigate([`/${AppConfig.routes.memberProfile.main}`, member.ref],
        { state: { prospectEnabled: member.isProspectInfo } });
    }
    this.form.controls.members.setValue({ label: '', value: '', ref: '', type: 'value' });
  }
}
