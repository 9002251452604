import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { Observable, of, pipe } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '@core/services/auth';
import { switchMap } from 'rxjs/operators';
import { getCurrentResetPasswordPolicyName } from '@config/msal.config';
import { GlobalStateService } from '@core/services/global.state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private globalStateService: GlobalStateService,
    private msalService: MsalService
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.msalService.handleRedirectObservable()
      .pipe(
        switchMap((result: AuthenticationResult) => {
          if (!!result && (result.idTokenClaims as any).tfp === getCurrentResetPasswordPolicyName()) {
            return of(false);
          }

          return of(!!this.globalStateService.user?.firstName);
        })
      );
  }
}
