import { map } from 'rxjs/operators';
import { GlobalStateService } from '@core/services/global.state.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EcmUsageGuardService implements CanActivate {

  constructor(
    private router: Router,
    private globalService: GlobalStateService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.globalService.ECMVisible$
      .pipe(
        map(visible => visible ? true : this.router.parseUrl('home'))
      );
  }
}
