export enum LinkType {
  member = 'member',
  task = 'task',
  conversation = 'conversation',
  account = 'account',
  sale = 'sale',
  note = 'note',
  file = 'file',
  prospect = 'prospect',
}
