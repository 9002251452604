import { SqlBuilderDataDto } from '@core/services/dto';

export class SqlBuilderDataModel {
  branchName: string;
  entityId: number;
  fullName: string;
  memberNumber: number;
  memberStatus: string;

  constructor(dto: SqlBuilderDataDto) {
    this.branchName = dto.BranchName;
    this.entityId = dto.EntityId;
    this.fullName = dto.FullName;
    this.memberNumber = dto.MemberNumber;
    this.memberStatus = dto.MemberStatus;
  }
}
