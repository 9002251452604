import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SaleUrls } from '@core/constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { CreateSalesApiRequest } from '@shared/models/requests/crete-sales.request.dto';
import { GetAllSalesRequestDto } from '@shared/models/requests/get-all-sales.request.dto';
import { PaginatedResponseDto, SalesDto, ViewSaleDto } from '@core/services/dto';

@Injectable({
  providedIn: 'root'
})
export class SalesApiService {
  constructor(private api: ApiClient) {}

  createSale(request: CreateSalesApiRequest): Observable<number> {
    return this.api.post<number>(SaleUrls.sales, request);
  }

  updateSale(id: number, request: CreateSalesApiRequest): Observable<any> {
    return this.api.put<any>(SaleUrls.sale(id), request);
  }

  getAllSales(request: GetAllSalesRequestDto): Observable<PaginatedResponseDto<SalesDto>> {
    return this.api.get<PaginatedResponseDto<SalesDto>> (SaleUrls.saleProducts, request);
  }

  getSale(id): Observable<ViewSaleDto> {
    return this.api.get<ViewSaleDto> (SaleUrls.sale(id));
  }

  deleteSale(id: number): Observable<any> {
    return this.api.delete<any>(SaleUrls.sale(id));
  }
}
