import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { GlobalStateService } from '@core/services/global.state.service';
import {
  ProductModel,
  ProductTypeModel,
  SalesProductModel,
  SearchMemberOptionsModel,
  UserNameModel
} from '@shared/models';
import { formatDateSimple } from '@shared/utils';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { Subject } from 'rxjs';
import { ProductCardStateService } from '@shared/components/product-card/product-card.state.service';
import { FundSourceOpt, SaleTypesOpt } from './product-cart.config';
import { fundSourceEnum, SaleTypesEnum } from '@shared/enums';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent extends autoUnsubscribeMixin() implements OnInit, OnChanges {
  form: FormGroup;
  productTypeOptions: ProductTypeModel[];
  productOptions: ProductModel[];
  saleTypesOpt = SaleTypesOpt;
  fundSourceOpt = FundSourceOpt;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  soldByUser: UserNameModel = new UserNameModel();
  defaultUserName = new Subject<SearchMemberOptionsModel>();
  soldByUsers$ = this.state.soldByUsers$();
  soldById: string;
  productTypeLabel: string;
  productLabel: string;
  getProductOptions: ProductModel;
  productValue: number;


  // inner mode flags
  isEdit = false;
  isView = false;
  isCreate = false;
  isSummaryVisible = false;
  productGet = this.globalState.products.value;

  @Input() id: number;
  @Input() product: SalesProductModel;

  // outer mode flags
  @Input() viewMode: boolean;
  @Input() createMode: boolean;
  @Input() editMode: boolean;

  @Output() deleteItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceling: EventEmitter<any> = new EventEmitter<any>();
  @Output() savedItem: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private globalState: GlobalStateService,
    private state: ProductCardStateService,
  ) {
    super();
    this.form = this.fb.group({
      productType: [null, Validators.required],
      productTypeName: [null],
      productName: [null],
      productId: [null, Validators.required],
      saleType: [SaleTypesEnum[SaleTypesEnum.NewSale], Validators.required],
      value: [null, Validators.required],
      actualvalue: [null],
      fundSource: [fundSourceEnum[fundSourceEnum.Internal], Validators.required],
      soldBy: [null, Validators.required],
      soldByUser: [null],
      renewalDate: [null],
      maturityDate: [null],
      renewalDateFormatted: [null],
      tempId: [null],
    });
  }

  ngOnInit(): void {

    this.globalState.getProductTypes();
    this.globalState.getProducts();
    this.soldByUser = { ...this.globalState.user };

    this.globalState.productTypes$().subscribe(productTypes => {
      this.productTypeOptions = productTypes;
    });

    this.globalState.products$().subscribe(products => {
      this.productOptions = products;
    });

    this.setCurrentMode();

    this.form.get('productType').valueChanges.subscribe(productTypeId => {
      this.globalState.getProducts(productTypeId);


    });
    this.form.get('value').valueChanges.subscribe(value => {
      this.form.get('actualvalue').setValue(value);
    });





    this.form.get('saleType').valueChanges.subscribe(type => {
      if (type === SaleTypesEnum[SaleTypesEnum.Renewal]) {
        this.form.get('fundSource').disable();
        this.form.get('fundSource').setValue(fundSourceEnum[fundSourceEnum.Internal]);
      } else {
        this.form.get('fundSource').enable();
      }
    });

    this.form.controls.soldBy.valueChanges.subscribe(name => {
      if (!name) {
        return;
      }

      if (typeof name === 'object') {
        this.soldById = name.ref;
      }
      else if (name?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch) {
        this.state.getSearchedUsers(name);
      }
    });

    this.setDefaultSoldByUserName();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product?.currentValue) {
      this.updateFormValues();
      this.soldByUser = this.product.soldBy;
    }
  }

  setCurrentMode(): void {
    if (this.viewMode || this.editMode) {
      this.isEdit = false;
      this.isSummaryVisible = true;
      this.isView = false;
    } else if (this.createMode) {
      this.isEdit = true;
      this.isSummaryVisible = false;
      this.isView = false;
      this.isCreate = true;
    }
  }

  updateFormValues(): void {
    if (this.product) {
      this.form.patchValue({
        productTypeName: this.product.productTypeName,
        productType: this.product.productType,
        productId: this.product.productId,
        productName: this.product.productName,
        saleType: this.product.saleType,
        value: this.product.value,
        fundSource: this.product.fundSource,
        soldByUser: this.product.soldByUser,
        renewalDate: this.product.renewalDate,
        renewalDateFormatted: this.product.renewalDate
          ? formatDateSimple(this.product.renewalDate)
          : null,
        actualvalue: this.product.actualValue,
        maturityDate: this.product.maturityDate
      });
      this.saveProductTypeLabel(this.product.productTypeName);
      this.saveProductLabel(this.product.productName);
      if (this.isView) {
        this.isSummaryVisible = true;
        this.isEdit = false;
      }
      if (this.product.saleType === SaleTypesEnum[SaleTypesEnum.Renewal]) {
        this.form.get('fundSource').disable();
        this.form.get('fundSource').setValue(fundSourceEnum[fundSourceEnum.Internal]);
      }else {
        this.form.get('fundSource').enable();
      }
    }
  }

  private setDefaultSoldByUserName(): void {
    if (this.soldByUser?.id) {
      setTimeout(() => {
        this.form.patchValue({
          soldBy: {
            label: this.soldByUser?.firstName + ' ' + this.soldByUser?.lastName,
            ref: this.soldByUser?.id,
            type: null,
            value: null
          }
        });
      }, 0);
    }
  }

  edit(): void {
    this.isEdit = true;
    this.isSummaryVisible = false;
    this.setDefaultSoldByUserName();
  }

  view(): void {
    this.isView = true;
    this.isSummaryVisible = false;
  }

  saveProductTypeLabel(label): void {
    this.productTypeLabel = label;
  }

  saveProductLabel(label): void {
    this.productLabel = label;
  }

  deleteTemporary(): void {
    this.deleteItem.emit(this.id);
  }

  delete(): void {
    this.deleteItem.emit(this.product?.id);
  }

  cancel(): void {
    if (!this.isView) {
      this.deleteTemporary();
    }
    this.isEdit = false;
    this.isView = false;
    this.isSummaryVisible = true;
  }

  save(): void {
    this.isSummaryVisible = true;
    this.isEdit = false;
    const product = this.form.getRawValue();
    product.tempId = this.id;
    product.id = this.product?.id;
    product.soldBy = this.soldByUser.id;
    product.renewalDate = product.renewalDate ? new Date(product.renewalDate).toJSON() : null;
    product.productTypeName = this.productTypeLabel;
    product.productName = this.productLabel;
    product.maturityDate = product.maturityDate;
    product.actualvalue = product.actualvalue;
    this.savedItem.emit(product);
  }

  getDate(): string {
    return this.form.controls.renewalDate.value ? formatDateSimple(this.form.controls.renewalDate.value) : '';
  }

  chooseSoldByUser(data): void {
    this.soldByUser.id = data.ref;
  }

}
