import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { InstancesModel, NoteModel, SearchAccountOptionsModel, SearchMemberOptionsModel } from '@shared/models';
import { CreateNoteStateService } from '@shared/components/create-note/create-note.state.service';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { FileTypeEnum } from '@shared/enums';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';
import { MemberProspectInstancesModel } from '@shared/models/memberprospectinstances.model';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss']
})
export class CreateNoteComponent extends autoUnsubscribeMixin() implements OnInit, AfterViewInit {
  form: FormGroup;
  formLinks: SearchMemberProspectOptionsModel[] = [];
  preselectedMember: SearchMemberProspectOptionsModel;
  preselectedAccount: SearchAccountOptionsModel[];
  note: NoteModel;
  isSticky = false;
  FileTypeEnum = FileTypeEnum;
  todayDate = new Date();
  initialValue: any = {};
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() formChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private state: CreateNoteStateService,
    private toast: CustomSnackbarService,
  ) {
    super();
    this.form = this.fb.group({
      description: ['', [Validators.maxLength(1000), Validators.minLength(3), Validators.required]],
      stickyDueDate: [null],
      isSticky: [false]
    });
  }

  ngOnInit(): void {
    this.state.member$().subscribe(
      data => {
        if (data) {
          this.preselectedMember = new SearchMemberProspectOptionsModel(data);
        }
      }
    );
    this.state.preselectedAccount$().subscribe(
      data => {
        if (data) {
          this.preselectedAccount = data;
        }
      }
    );

  }

  checkFormChange(): void {
    setTimeout(() => {
      this.initialValue = JSON.stringify(this.form.value);
      this.form.valueChanges.subscribe((value) => {
        if (JSON.stringify(value) !== this.initialValue) {
          this.formChanged.emit(true);
        }
      });
    }, 2000);
  }

  ngAfterViewInit(): void {
    this.checkFormChange();
  }

  addInstance(links: SearchMemberProspectOptionsModel[]): void {
    const acc = [];
    links.forEach(item => {
      acc.push(new MemberProspectInstancesModel(item));
    });
    this.formLinks = acc;
    this.formChanged.emit(true);
  }

  get isNoteDisabled(): boolean {
    return !this.formLinks.length;
  }

  record(): void {
    delete this.form.value.isSticky;
    this.note = {
      ...this.form.value,
      attachments: this.form.value.attachments.map(x => x.id)
    };
    // TO DO :- change model from searchmemberoptionsmodel to searchmembersprospectoptionsmodel
    if (this.formLinks?.length) {
      this.formLinks.forEach(element => {
        if (element.isProspectInfo === true) {
          element.type = 'Prospect';
        } else if (element.isProspectInfo === false) {
          element.type = 'Member';
        }
        else {
          element.type = element.type;
        }
      });
    }
    this.note.links = this.formLinks;
    this.note.stickyDueDate = !!this.form.value.stickyDueDate ? new Date(this.form.value.stickyDueDate).toJSON() : null;

    this.state.setNote(this.note).subscribe(data => {
      this.toast.success('Note successfully added');
      this.formChanged.emit(false);
      if ('noteId' in data) {
        this.closeDialog.emit();
      }
    });
  }

  updateSticky(): void {
    const dueDate = this.form.controls.stickyDueDate;
    this.form.get('isSticky').value ? dueDate.setValidators(Validators.required) : dueDate.clearValidators();
    dueDate.updateValueAndValidity();
  }

}
