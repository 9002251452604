import { ApplicationsDto } from '@core/services/dto';

export class ApplicationsModel {
  applicants: { firstName: string; lastName: string; type: string }[];
  applicationType: string;
  dateAssignedTo: string;
  dateViewed: string;
  products: string[];
  publicId: string;
  id: number;
  status: string;

  constructor(dto: ApplicationsDto) {
    for (const prop in dto) {
      if (dto.hasOwnProperty(prop)) {
        this[prop] = dto[prop];
      }
    }
    this.products = [];
    this.applicants?.forEach(item => {
      item.type = 'Member';
    });
    dto.products.forEach(product => {
      this.products.push(` ${product?.name}`);
    });
  }
}
