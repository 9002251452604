import { ApplicationsTypeEnum } from '@shared/constants/select-options.enums';
import {
  getCurrentAddressSearchBase,
  getCurrentAosBase,
  getCurrentApiBase,
  getCurrentAsappApiBase,
  getCurrentEmailBase,
  getCurrentMemberBase,
  getCurrentUserManagementBase,
  getReportBase,
  getKeyVaultBase,
  getEcmBase,
  getVersioningBase,
  getOfferApiBase,
  getLendingDecisioningApiBase
} from '@config/org.config';

const apiBase = getCurrentApiBase();
const memberBase = getCurrentMemberBase();
const emailBase = getCurrentEmailBase();
const canadaPostAddressBase = getCurrentAddressSearchBase();
const userManagementBase = getCurrentUserManagementBase();
const reportingBase = getReportBase();
const asappApiBase = getCurrentAsappApiBase();
const keyVaultBase = getKeyVaultBase();
const ecmBase = getEcmBase();
const versioningBase = getVersioningBase();
const offerBase = getOfferApiBase();
const LDSBase = getLendingDecisioningApiBase();

export const AuthUrls = {
  login: `${userManagementBase}/login`,
};

export const EmailUrls = {
  templates: `${emailBase}/templates`,
  templateById: (id: string) => `${emailBase}/templates/${id}`,
  sendBulk: `${emailBase}/send/bulk`,
  send: `${emailBase}/send`,
  attachments: `${emailBase}/attachments`,
  attachment: (id: number) => `${emailBase}/attachments/${id}`,
  layout: `${emailBase}/layouts`,
  defaultLayout: `${emailBase}/layouts/default`
};

export const MemberUrls = {
  member: (memberId: number) => `${memberBase}/members/${memberId}`,
  review: (memberId: number) => `${memberBase}/members/${memberId}/review`,
  members: `${memberBase}/members`,
  import: (memberNumber: string, memberType: string) =>
    `${memberBase}/members/${memberNumber}/${memberType}/sync`,
  importById: (memberId: number) => `${memberBase}/members/${memberId}/sync`,
  coreBanking: (memberNumber: number) =>
    `${memberBase}/corebanking/members?memberNumber=${memberNumber}`,
  bankIntegrationConfiguration: `${memberBase}/corebanking/bank-integration-configuration`,
  summary: (memberId: number, statuses: string[], isProspectInfo: boolean) =>
    `${apiBase}/member-summary?memberId=${memberId}&taskStatuses=${statuses}&isProspectInfo=${isProspectInfo}`,
  person: (personId: number) => `${memberBase}/persons/${personId}`,
  organization: (orgId: number) => `${memberBase}/organizations/${orgId}`,
  addresses: (personId: number, type: string) =>
    `${memberBase}/${type}/${personId}/addresses`,
  address: (personId: number, type: string, addressId: number) =>
    `${memberBase}/${type}/${personId}/addresses/${addressId}`,
  createAddress: (type: string, personId: number) =>
    `${memberBase}/${type}/${personId}/addresses`,
  employments: (personId: number) =>
    `${memberBase}/persons/${personId}/employments`,
  updateEmployment: (personId: number, employmentId: number) =>
    `${memberBase}/persons/${personId}/employments/${employmentId}`,
  contacts: (personId: number) => `${memberBase}/persons/${personId}/contacts`,
  personContacts: `${memberBase}/persons/contacts`,
  organizationContacts: `${memberBase}/organizations/contacts`,
  orgContacts: (orgId: number) =>
    `${memberBase}/organizations/${orgId}/contacts`,
  preferences: (personId: number) =>
    `${memberBase}/persons/${personId}/contacts/preferences`,
  orgPreferences: (orgId: number) =>
    `${memberBase}/organizations/${orgId}/contacts/preferences`,
  updateContact: (personId: number, contactId: number) =>
    `${memberBase}/persons/${personId}/contacts/${contactId}`,
  updateOrgContact: (orgId: number, contactId: number) =>
    `${memberBase}/organizations/${orgId}/contacts/${contactId}`,
  deleteContact: (personId: number, contactId: number) =>
    `${memberBase}/persons/${personId}/contacts/${contactId}`,
  deleteOrgContact: (orgId: number, contactId: number) =>
    `${memberBase}/organizations/${orgId}/contacts/${contactId}`,
  identificationDocuments: (personId: number, type: string) =>
    `${memberBase}/${type}/${personId}/documents`,
  branches: `${memberBase}/branches`,
  syncAccounts: (memberId: number) =>
    `${memberBase}/members/${memberId}/accounts/sync`,
  lendings: (accountId: number) => `${memberBase}/lendings/${accountId}`,
  lendingHolds: (accountId: number) =>
    `${memberBase}/lendings/${accountId}/holds`,
  lendingCollateral: (accountId: number) =>
    `${memberBase}/lendings/${accountId}/collateral`,
  savings: (accountId: number) => `${memberBase}/savings/${accountId}`,
  savingHolds: (accountId: number) =>
    `${memberBase}/savings/${accountId}/holds`,
  allLendings: (memberId: number) =>
    `${memberBase}/members/${memberId}/lendings`,
  allSavings: (memberId: number) => `${memberBase}/members/${memberId}/savings`,
  provinces: `${memberBase}/provinces`,
  people: (memberId: number) => `${memberBase}/members/${memberId}/joints`,
  joints: (jointId: number) => `${memberBase}/joints/${jointId}`,
  accounts: `${memberBase}/accounts`,
  accountsCount: (memberId: number) =>
    `${memberBase}/accounts/count?memberId=${memberId}`,
  households: `${memberBase}/households`,
  household: (householdId: number) => `${memberBase}/households/${householdId}`,
  offerCount: (memberId: number, statuses: string[]) =>
    `${offerBase}/offer-members-count?memberId=${memberId}&OfferStatuses=${statuses}`,
  accessHistory: (memberId: number) =>
    `${memberBase}/profilehistory?MemberorProspectId=${memberId}`,
  addRepresentative: `${memberBase}/memberrepresentatives`,
  getMemberRepresentative: (memberId: number) => `${memberBase}/memberrepresentative/${memberId}/M`,
  getProspectRepresentative: (prospectId: number) => `${memberBase}/memberrepresentative/${prospectId}/P`,
  updateRepresentative: `${memberBase}/memberrepresentative`,
  deleteRepresentative: (id) => `${memberBase}/memberrepresentative/${id}`,
  checkUserMemberRepresentativeAdded: (userId, userGroupId) => `${memberBase}/memberrepresentative/isuserorusergroupexist?UserId=${userId}&UserGroupId=${userGroupId}`,
  checkGroupMemberRepresentativeAdded: (UserGroupId) => `${memberBase}/memberrepresentative/isuserorusergroupexist?UserGroupId=${UserGroupId}`,
  deleteUserGroupFromMemberRepresentative: (userGroupId) => `${memberBase}/memberrepresentative?UserGroupId=${userGroupId}`,
  deleteUserFromMemberRepresentative: (userId, userGroupId) => `${memberBase}/memberrepresentative?UserId=${userId}&UserGroupId=${userGroupId}`,
};

export const UserUrls = {
  users: `${apiBase}/users`,
  userGroups: `${apiBase}/usergroups`,
};

export const LinksUrls = {
  linksPrivate: `${apiBase}/links/private`,
  linkPrivate: (id: number) => `${apiBase}/links/${id}/private`,
  linksPublic: `${apiBase}/links/public`,
  linkPublic: (id: number) => `${apiBase}/links/${id}/public`,
};

export const TaskUrls = {
  tasks: `${apiBase}/tasks`,
  task: (id: number) => `${apiBase}/tasks/${id}`,
  taskTemplates: `${apiBase}/task-templates`,
  taskTemplate: (id: number) => `${apiBase}/task-templates/${id}`,
  summary: (statuses: string[]) => `${apiBase}/task-summary?taskStatuses=${statuses}`,
  updateSendDate: (id: string, emailId: string) => `${apiBase}/tasks/${id}/emails/${emailId}`
};

export const ConversationUrls = {
  conversations: `${apiBase}/conversations`,
  concerns: `${apiBase}/concerns`,
  concerntypes: `${apiBase}/concern-types`,
  opportunities: `${apiBase}/opportunities`,
  conversation: (id: number) => `${apiBase}/conversations/${id}`,
  concern: (id: number) => `${apiBase}/concerns/${id}`,
  concerntype: (id: number) => `${apiBase}/concern-types/${id}`,
  opportunity: (id: number) => `${apiBase}/opportunities/${id}`,
  summary: `${apiBase}/conversation-summary`,
  conversationWorkflows: (id: number) => `${apiBase}/conversations/${id}/workflows`,
  conversationImports: `${apiBase}/conversation-imports`,
  conversationImport: (id: number) => `${apiBase}/conversation-imports/${id}`,
  conversationImportTemplate: (id: number) => `${apiBase}/conversation-imports/template/${id}`,
  importedConversationEmails: `${apiBase}/GetImportedConversationEmails`,
  conversationAssineetBatch:  `${apiBase}/conversations/BatchAssignment`,
  concernsAssineetBatch:  `${apiBase}/concerns/BatchAssignment`,
  opportunitiesAssineetBatch:  `${apiBase}/opportunities/BatchAssignment`,
  tasksAssineetBatch:  `${apiBase}/tasks/BatchAssignment`,
  isCrmObjectExists: (id: number) => `${apiBase}/usergroupsummary/activeCRMrecords?assigneeId=${id}`,
};

export const ReportUrls = {
  salesReport: `${apiBase}/reports/sales-report`,
  salesReportExport: `${apiBase}/reports/sales-report/export`,
  opportunityReport: `${apiBase}/reports/opportunity-status-report`,
  taskReport: `${apiBase}/reports/task-status-report`,
  opportunityReportExport: `${apiBase}/reports/opportunity-status-report/export`,
  taskReportExport: `${apiBase}/reports/task-status-report/export`,
  interactionReport: `${apiBase}/reports/interactions-report`,
  pipelineReport: `${apiBase}/reports/pipeline-report`,
  userReport: `${userManagementBase}/reports/license-report`,
  userReportExport: `${userManagementBase}/reports/license-report/export`,
  referralReport: `${apiBase}/reports/referral-report`,
  referralReportExport: `${apiBase}/reports/referral-report/export`,
  offerActivityReport: `${offerBase}/reports/offer-activity-report`,
  offerActivityReportExport: `${offerBase}/reports/offer-activity-report/export`,
  pipelineReportExport: `${apiBase}/reports/pipeline-report/export`,
};

export const SqlBuilderUrls = {
  sqlBuilderOptions: `${reportingBase}/options`,
  sqlTemplates: `${reportingBase}/templates`,
  sqlTemplate: (id: number) => `${reportingBase}/templates/${id}`,
  queryData: `${reportingBase}/templates/build`,
  export: `${reportingBase}/templates/export`,
  exportOpportunities: `${reportingBase}/templates/export-for-opportunity-import`,
  exportOffers: `${reportingBase}/templates/export-for-offer-import`
};

export const ProductUrls = {
  productTypes: `${apiBase}/product-types`,
  products: `${apiBase}/products`,
  campaigns: `${apiBase}/campaigns`,
  campaign: (id: number) => `${apiBase}/campaigns/${id}`,
};

export const InteractionUrls = {
  interactions: `${apiBase}/interactions`,
  interaction: (id: number) => `${apiBase}/interactions/${id}`
};

export const AnnouncementUrls = {
  announcements: `${apiBase}/announcements`,
  announcementUser: `${apiBase}/announcements/user`,
  announcement: (id: number) => `${apiBase}/announcements/${id}`
};

export const RecentActivityUrls = {
  summary: `${apiBase}/summary`,
  memberSummary: `${memberBase}/summary`
};

export const NoteUrls = {
  notes: `${apiBase}/notes`,
  note: (id: number) => `${apiBase}/notes/${id}`,
};

export const WorkflowUrls = {
  workflowTemplates: `${apiBase}/workflow-templates`,
  workflowTemplate: (id: number) => `${apiBase}/workflow-templates/${id}`,
  workflow: (id: number) => `${apiBase}/workflows/${id}`,
  workFlowTasksAssineetBatch:  `${apiBase}/workflow-templates/BatchAssignment`,
};

export const FileUrls = {
  files: `${apiBase}/files`,
  file: (id: number) => `${apiBase}/files/${id}`
};

export const CanadaPostAddressUrls = {
  search: (search: string, id: string, IsGoogleActivated: boolean, countryCode: string) => `${canadaPostAddressBase}/address-search/find?search=${search}&id=${id}&isgoogleActivated=${IsGoogleActivated}&countryCode=${countryCode}`,
  get: (id: string, IsGoogleActivated: boolean, countryCode: string) => `${canadaPostAddressBase}/address-search/retrieve?id=${id}&isgoogleActivated=${IsGoogleActivated}&countryCode=${countryCode}`,
};

export const UserManagementUrls = {
  users: `${userManagementBase}/users`,
  user: (id: string) => `${userManagementBase}/users/${id}`,
  permissions: (id: string) => `${userManagementBase}/users/${id}/permissions`,
  groups: (id: string) => `${userManagementBase}/users/${id}/groups`,
};

export const UserGroupManagementUrls = {
  groups: `${userManagementBase}/groups`,
  groupDetails: (id: number) => `${userManagementBase}/groups/${id}`,
  groupUsers: (id: number) => `${userManagementBase}/groups/${id}/users`,
  userGroupDetails: (id: number, groupId: number) => `${userManagementBase}/users/${id}/groups/${groupId}`
};

export const EmailNotificationUrls = {
  emailNotifications: (hasGroup: boolean) => `${userManagementBase}/EmailNotificationPermission?hasGroup=${hasGroup}`,
  emailNotification: `${userManagementBase}/EmailNotificationPermission`
};

export const SaleUrls = {
  sales: `${apiBase}/sales`,
  sale: (id: number) => `${apiBase}/sales/${id}`,
  saleProducts: `${apiBase}/sale-products`
};

export const AsappServiceUrls = {
  applications: `${asappApiBase}/applications`,
  statuses: `${asappApiBase}/applications/counts`,
  occupations: `${asappApiBase}/site/occupations`,
  autocomplete: `${asappApiBase}/site/getGoogleAutocompleteEnabled`,
  siteDetails: `${asappApiBase}/site/getSiteDetails`,
  userBranch: `${asappApiBase}/site/getBranch`,
};

export const CrmUrls = {
  branches: (type: 'Site' | 'Client') => `${apiBase}/branches?type=${type}`,
  weather: `${apiBase}/weather`,
  forecast: `${apiBase}/forecast`
};

export const KeyVaultUrls = {
  keyVaultValue: (key: string) => `${keyVaultBase}?key=${key}`
};

export const CommonUrls = {
  sites: `${userManagementBase}/sites`,
  site: `${userManagementBase}/sites/site`,
};

export const TagsUrls = {
  tags: `${ecmBase}/tags`,
  tag: (id: number) => `${ecmBase}/tags/${id}`,
};

export const ThemeUrls = {
  getStyle: (type: 'CustomThemeColors' | 'CustomStyles' | 'DefaultThemeColors') => `${apiBase}/theme-builder/styles/${type}`,
  themeStyles: `${apiBase}/theme-builder/styles`,
  themeImage: `${apiBase}/theme-builder/image`
};

export const AosUrls = {
  application: (type: ApplicationsTypeEnum, id: number) => `${getCurrentAosBase()}/manage/${type === ApplicationsTypeEnum.Retail ? 'applications' : 'businessapplications'}/edit/${id}`,
  applications: (type: ApplicationsTypeEnum, status?: number) => {
    const statusFilter = (status === null || status === undefined) ? '' : status;
    const appType = type === ApplicationsTypeEnum.Retail ? 'applications' : 'businessapplications';
    const url = `${getCurrentAosBase()}/manage/${appType}?Status=${statusFilter}`;

    return url;
  }
};

export const EcmUrls = {
  file: `${ecmBase}/file`,
  files: `${ecmBase}/files`,
  archivefiles: `${ecmBase}/files/archive`,
  fileById: (id: number) => `${ecmBase}/files/${id}`,
  filesSummary: `${ecmBase}/files-summary`,
  uploadFile: `${ecmBase}/files/upload`,
  loadFile: `${ecmBase}/files/load`,
  folders: `${ecmBase}/folders`,
  folderById: (id: number) => `${ecmBase}/folders/${id}`,
  folderContent: `${ecmBase}/folders/content`,
  types: `${ecmBase}/file-types`,
  getSubTypes: `${ecmBase}/file-sub-types/`,
  checkECMFolderGroup: (groupId) => `${ecmBase}/folders/checkusergroupassociation/${groupId}`,
};

export const VersioningUrls = {
  versioningDetails: (version: string) => `${versioningBase}/version-search/retrieve?version=${version}`,
  currentUmbrellaVersion: (EnvironmentName: string) => `${versioningBase}/umbrella-version/retrieveUmbrellaVersion?environment=${EnvironmentName}`
};

export const OfferUrls = {
  OfferTemplates: `${offerBase}/offer-templates`,
  offerTemplate: (id: number) => `${offerBase}/offer-template/${id}`,
  OfferTemplateIcons: (id: number) => `${offerBase}/offer-Template-Icons`,
  files: `${offerBase}/files`,
  offerImports: `${offerBase}/offer-imports`,
  offerImport: (id: number) => `${offerBase}/offer-imports/${id}`,
  offerImportTemplate: `${offerBase}/offer-imports/template`,
  offers: `${offerBase}/offer-members`,
  offer: (id: number) => `${offerBase}/offer-members/${id}`,
  offerMemberStatus: (id: number) => `${offerBase}/offerMemberStatus/${id}`,
  offerActivity: `${offerBase}/offer-activity-logs`,
  MemberOffers: `${offerBase}/offer-members`,
  OfferSite: `${offerBase}/site`,
  OfferUser: `${offerBase}/offer-user`,
  offerUsers: `${offerBase}/offer-users`,
  OfferBranches: (siteId: string) => `${offerBase}/offer-branches/${siteId}`,
  OfferUserEnable: `${offerBase}/offer-user-enable`,
};

export const ProspectUrls = {
  Prospect: `${memberBase}/Prospect/Add`,
  prospect: (memberId: number) => `${memberBase}/prospectsummary/${memberId}`,
  importProspect: (memberNumber: string, memberType: string) =>
    `${memberBase}/prospectsummary/${memberNumber}/${memberType}/sync`,
  importProspectById: (memberId: number) => `${memberBase}/prospectsummary/${memberId}/prospectsync`,
  prospectStatus: `${memberBase}/Prospect/UpdateStatus`,
  deleteProspect: (ProspectId: number) => `${memberBase}/Prospect/Delete/${ProspectId}`,
  mergeProspect: `${memberBase}/Prospect/MergeProspect`,
  Status: (Id: number, ProspectStatus: string) => `${memberBase}/prospectsummary/${Id}/${ProspectStatus}`,
  prospectInformation: (ProspectId: number) => `${memberBase}/Prospect/GetProspectById/${ProspectId}`,
  updateProspectInformation: `${memberBase}/Prospect/Update`,
  prospectContactPreference: (ProspectId: number) => `${memberBase}/prospectsummary/GetProspectContactById/${ProspectId}`,
  updateProspectContactPreference: `${memberBase}/prospectsummary/UpdateProspectContact`,
  getProspectContact: (prospectId: number, contactId: number) => `${memberBase}/prospects/${prospectId}/contacts/${contactId}`,
  createProspectContacts: (prospectId: number) => `${memberBase}/prospects/${prospectId}/contacts`,
  updateProspectContact: (prospectId: number, contactId: number) => `${memberBase}/prospects/${prospectId}/contacts/${contactId}`,
  deleteProspectContact: (prospectId: number, contactId: number) => `${memberBase}/prospects/${prospectId}/contacts/${contactId}`,
  accessHistory: (memberId: number) =>
    `${memberBase}/profilehistory?MemberorProspectId=${memberId}`,
  prospectaddresses: (personId: number, type: string) =>
    `${memberBase}/${type}/${personId}/addresses?pageNumber=0&pageSize=5`,
  deleteAddresses: (addressId: number, personId: number) =>
    `${memberBase}/prospect/${personId}/addresses/${addressId}`,
};


export const LendingDecisioningUrls = {
  approvalModels: `${LDSBase}/ApprovalModel/GetAll`,
  approvalModel: (id: number) => `${LDSBase}/ApprovalModel/GetById?id=${id}`,
  approvalModelByVersion: (version: string, id: number) =>
    `${LDSBase}/ApprovalModel/ApprovalModelByVersionName?id=${id}&versionName=${version}`,
  approvalModelCreate: `${LDSBase}/ApprovalModel/AddApproval`,
  approvalModelsName: `${LDSBase}/ApprovalModel/GetModelsNameForImport`,
  approvalModelUpdate: `${LDSBase}/ApprovalModel/UpdateApproval`,
  approvalModelDelete: (id: number) =>
    `${LDSBase}/ApprovalModel/DeleteApproval?approvalModelId=${id}`,
  approvalExclusionParameter: `${LDSBase}/Parameter/GetParameterList`,
  rateModels: `${LDSBase}/RateModel/GetAllRates`,
  rateModelDelete: (id: number) =>
    `${LDSBase}/RateModel/DeleteRate?rateModelId=${id}`,
  rateModel: (id: number) => `${LDSBase}/RateModel/GetById?id=${id}`,
  rateModelByVersion: (version: string, id: number) =>
    `${LDSBase}/RateModel/RateModelByVersionName?id=${id}&versionName=${version}`,
  rateModelUpdate: `${LDSBase}/RateModel/UpdateRate`,
  rateModelCreate: `${LDSBase}/RateModel/AddRate`,
  rateModelsName: `${LDSBase}/RateModel/GetRateModelsNameForImport`,
  rateModifierParameter: `${LDSBase}/Parameter/GetParameterList`,
  rateMatrixParameter: `${LDSBase}/Parameter/GetParameterList`,
};

