import { take } from 'rxjs/operators';
import {
  getCurrentAosBase,
  getIsAosRole,
  getIsCrmRole,
} from '@config/org.config';
import { KeyVaultApiService } from '@core/services/api.services/key-vault-api.service';
import { PermissionStringEnum } from '@shared/enums';
import { of } from 'rxjs';
import { GlobalStateService } from '@core/services/global.state.service';
import { AuthService } from '@core/services/auth';

export const GET_MENU_ITEMS = (
  globalStateService: GlobalStateService,
  auth: AuthService,
  isOfferOnly: boolean,
  isOfferAOSOnly: boolean,
  isOfferDisabled: boolean
) => {
  const sqlBuilderPageVisible$ = globalStateService.sqlBuilderPageVisible$.pipe(
    take(1)
  );
  const ECMvisible$ = globalStateService.ECMVisible$.pipe(take(1));
  const Offervisible$ = globalStateService.offerVisible$.pipe(take(1));
  const menuItems = [
    {
      label: 'Dashboard',
      url: '/dashboard',
      isVisible: true,
      notEmpty: true,
      class: 'dashboard',
      newWindow: false,
    },
    {
      label: 'AOS / LOS',
      url: `${getCurrentAosBase()}/manage`,
      notEmpty: true,
      isVisible: () => of(getIsAosRole() && isOfferAOSOnly),
      class: 'aos_los',
      newWindow: true,
    },
    {
      label: 'Member Management',
      url: '',
      class: 'group',
      newWindow: false,
      child_items: [
        {
          label: 'Member & Prospect Search',
          permission: PermissionStringEnum.ViewMembers,
          url: '/search',
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'Recent Profiles',
          permission: PermissionStringEnum.ViewMembers,
          url: '/recent-profiles',
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'Tasks',
          permission: PermissionStringEnum.ViewAllTasks,
          url: '/tasks',
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'Conversations',
          permission: PermissionStringEnum.ViewConversations,
          url: '/conversations',
          isVisible: isOfferOnly,
          newWindow: false,
          sub_child_items: [
            {
              label: 'View All',
              permission: PermissionStringEnum.ViewConversations,
              url: '/conversations',
              newWindow: false,
            },
            {
              label: 'Import',
              permission: PermissionStringEnum.ImportOpportunities,
              url: '/import-conversation',
              newWindow: false,
            },
          ],
        },
        {
          label: 'Opportunities',
          permission: PermissionStringEnum.ViewConversations,
          newWindow: false,
          isVisible: isOfferOnly,
          isView: false,
          sub_child_items: [
            {
              label: 'View All',
              permission: PermissionStringEnum.ViewConversations,
              url: '/opportunities',
              newWindow: false,
            },
            {
              label: 'Import',
              permission: PermissionStringEnum.ImportOpportunities,
              url: '/import-opportunities',
              newWindow: false,
            },
          ],
        },
        {
          label: 'Concerns',
          permission: PermissionStringEnum.ViewConversations,
          isVisible: isOfferOnly,
          newWindow: false,
          isView: false,
          sub_child_items: [
            {
              label: 'View All',
              permission: PermissionStringEnum.ViewConversations,
              url: '/concerns',
              newWindow: false,
            },
            {
              label: 'Import',
              permission: PermissionStringEnum.ImportOpportunities,
              url: '/import-concern',
              newWindow: false,
            },
          ],
        },
        {
          label: 'Assigned Applications',
          isVisible: () =>
            of(getIsAosRole() && auth.isApplicationAvailable && isOfferAOSOnly),
          url: '/assigned-applications',
          newWindow: false,
        },
        {
          label: 'Recent Applications',
          isVisible: () =>
            of(getIsAosRole() && auth.isApplicationAvailable && isOfferAOSOnly),
          url: '/recent-applications',
          newWindow: false,
        },
        {
          label: 'Sales',
          permission: [
            PermissionStringEnum.ViewAllSales,
            PermissionStringEnum.ViewMySales,
          ],
          url: '/sales',
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'Announcements',
          url: '/user-announcements',
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'Offers',
          permission: PermissionStringEnum.ViewOffer,
          newWindow: false,
          isVisible: isOfferDisabled,
          isView: false,
          sub_child_items: [
            {
              label: 'Import',
              permission: PermissionStringEnum.AddOffer,
              url: '/import-offers',
              isVisible: isOfferDisabled,
              newWindow: false,
            },
            {
              label: 'View All',
              permission: PermissionStringEnum.ViewOffer,
              url: '/offers',
              isVisible: isOfferDisabled,
              newWindow: false,
            },
          ],
        },
      ],
    },
    {
      label: 'Content Management',
      notEmpty: false,
      url: '',
      isVisible: () => of(getIsCrmRole() || auth.isAosAdmin || !isOfferOnly),
      class: 'content_management',
      newWindow: false,
      child_items: [
        {
          label: 'Workflows',
          permission: PermissionStringEnum.ManageWorkflowTemplates,
          url: '/workflow-templates',
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'E-mail Templates',
          permission: PermissionStringEnum.ManageEmailTemplates,
          url: `/email-templates`,
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'Task Templates',
          permission: PermissionStringEnum.ManageTaskTemplates,
          url: '/task-templates',
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'Offer Templates',
          permission: PermissionStringEnum.ManageOfferTemplates,
          url: '/offer-templates',
          isVisible: isOfferDisabled,
          newWindow: false,
        },
        {
          label: 'Links',
          notEmpty: false,
          newWindow: false,
          isVisible: isOfferAOSOnly,
          sub_child_items: [
            {
              label: 'Private Links',
              url: '/private-links',
              newWindow: false,
            },
            {
              label: 'Public Links',
              permission: [
                PermissionStringEnum.ManagePublicLinks,
                PermissionStringEnum.AosAdmin,
              ],
              url: '/public-links',
              newWindow: false,
            },
          ],
        },
        {
          label: 'Campaign Management',
          permission: PermissionStringEnum.ManageCampaigns,
          url: '/campaign-management',
          isVisible: isOfferOnly,
          newWindow: false,
        },
        {
          label: 'Announcements',
          permission: [
            PermissionStringEnum.ManageAnnouncements,
            PermissionStringEnum.AosAdmin,
          ],
          url: '/view-announcements',
          isVisible: isOfferAOSOnly,
          newWindow: false,
        },
        // TODO: Update permission with the right one
        {
          label: 'Tag management',
          permission: PermissionStringEnum.ManageTags,
          isVisible: () => of(ECMvisible$ && isOfferOnly),
          url: '/tag-management',
          newWindow: false,
        },
        // TODO: Update with right permission
        {
          label: 'ECM',
          permission: [
            PermissionStringEnum.ECMUser,
            PermissionStringEnum.ECMAdmin,
          ],
          isVisible: () => of(ECMvisible$ && isOfferOnly),
          url: '/ecm',
          newWindow: false,
        },
      ],
    },
    {
      label: 'Setup',
      notEmpty: false,
      url: '',
      isVisible: () => of(getIsCrmRole() || auth.isAosAdmin || !isOfferOnly),
      class: 'setup',
      child_items: [
        {
          label: 'Theme Builder',
          url: `/theme-builder`,
          isVisible: !auth.IsOfferAdmin,
          permission: [
            PermissionStringEnum.ManageUsers,
            PermissionStringEnum.AosAdmin,
          ],
          newWindow: false,
        },
        {
          label: 'User Management',
          permission: PermissionStringEnum.ManageUsers,
          url: `/user-management`,
          newWindow: false,
        },
        {
          label: 'User Group Management',
          permission: PermissionStringEnum.ManageUsers,
          isVisible: () => of(ECMvisible$ && isOfferOnly),
          url: `/user-group-management`,
          newWindow: false,
        },
        {
          label: 'multiMATRIX Models',
          newWindow: false,
          isVisible: () => of(auth.isAosAdmin || isOfferOnly),
          isView: false,
          sub_child_items: [
            {
              label: 'Approval Models',
              url: `/approval-models`,
              newWindow: false,
            },
            {
              label: 'Rate Models',
              url: `/rate-models`,
              newWindow: false,
            },
          ],
        },
      ],
    },
    {
      label: 'Reports',
      notEmpty: false,
      url: '',
      isVisible: () => of(getIsCrmRole() || isOfferDisabled),
      class: 'document-loupe',
      child_items: [
        {
          label: 'Sales Reporting',
          permission: PermissionStringEnum.ViewReports,
          url: `/reports/sale-reporting`,
          isVisible: () => of(isOfferOnly),
          newWindow: false,
        },
        {
          label: 'Opportunity Reporting',
          permission: PermissionStringEnum.ViewReports,
          url: `/reports/opportunity-reporting`,
          isVisible: () => of(isOfferOnly),
          newWindow: false,
        },
        {
          label: 'Interaction Reporting',
          permission: PermissionStringEnum.ViewReports,
          url: `/reports/interaction-reporting`,
          isVisible: () => of(isOfferOnly),
          newWindow: false,
        },
        {
          label: 'Pipeline Reporting',
          permission: PermissionStringEnum.ViewReports,
          url: `/reports/pipeline-reporting`,
          isVisible: () => of(isOfferOnly),
          newWindow: false,
        },
        {
          label: 'User Reporting',
          permission: PermissionStringEnum.ViewLicenseReport,
          url: `/reports/user-reporting`,
          isVisible: () => of(isOfferOnly),
          newWindow: false,
        },
        {
          label: 'Task Reporting',
          permission: PermissionStringEnum.ViewReports,
          url: `/reports/task-reporting`,
          newWindow: false,
        },
        {
          label: 'Opportunity Engine',
          permission: PermissionStringEnum.SQLBuilder,
          isVisible: () => sqlBuilderPageVisible$,
          url: `/reports/opportunity-engine`,
          newWindow: false,
        },
        {
          label: 'Offer Activity Report',
          permission: PermissionStringEnum.OfferActivityReport,
          url: `/reports/offer-activity-reporting`,
          isVisible: isOfferDisabled,
          newWindow: false,
        },
        {
          label: 'Referral Reporting',
          permission: PermissionStringEnum.ViewReports,
          url: `/reports/refferal-reporting`,
          newWindow: false,
        },
      ],
    },
  ];

  return menuItems;
};
