import { TaskTemplateRequest } from '@shared/models/requests/task-template.request.dto';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TaskApiService } from '@core/services/api.services/task-api.service.js';
import { GlobalStateService } from '@core/services/global.state.service';
import { Task, SearchAccountOptionsModel, TaskCreateInterface } from '@shared/models';
import { EmailTemplateDto, PaginatedResponseDto, TaskTemplateDto, UserDto } from '@core/services/dto';
import { UserApiService } from '@core/services/api.services/user-api.service';
import { TaskTemplateStatus } from '@shared/models/task-template-status.model';
import { UserApiRequest } from '@shared/models/requests/user.request.dto';
import { SearchUserOptionsModel } from '@shared/models/search.user.options.model';
import { TaskTemplateUseTypeEnum } from '@shared/models/task-template-use-type.model';
import { filter, map } from 'rxjs/operators';
import { UserStatusTypeStringEnum } from '@shared/enums/user-status-type.enum';
import { GroupTypeStringEnum } from '@shared/enums/group-type.enum';

@Injectable({ providedIn: 'any' })

export class CreateTaskStateService {
  private taskTemplates: BehaviorSubject<TaskTemplateDto[]> = new BehaviorSubject([]);
  private emailTemplates: BehaviorSubject<PaginatedResponseDto<EmailTemplateDto>> =
    new BehaviorSubject<PaginatedResponseDto<EmailTemplateDto>>(null);

  public taskTemplates$: Observable<TaskTemplateDto[]> = this.taskTemplates.asObservable();
  public users: BehaviorSubject<SearchUserOptionsModel[]> = new BehaviorSubject<SearchUserOptionsModel[]>([]);

  public emailTemplates$: Observable<PaginatedResponseDto<EmailTemplateDto>> = this.emailTemplates
    .asObservable()
    .pipe(
      filter(x => !!x)
    );

  users$(): Observable<any> {
    return this.users.asObservable();
  }

  constructor(
    private service: TaskApiService,
    private globalState: GlobalStateService,
    private userService: UserApiService
  ) { }

  createTask(task: TaskCreateInterface): Observable<any> {
    return this.service.createTask(task);
  }

  member$(): Observable<any> {
    return this.globalState.memberProfile;
  }
  getUsers(searchString: string): Observable<PaginatedResponseDto<UserDto>> {
    const request: UserApiRequest = { pageSize: 3, search: searchString };
    return this.userService.getUsers(request);
  }

  getUserGroups(searchString: string): Observable<PaginatedResponseDto<UserDto>> {
    const request: UserApiRequest = { pageSize: null, search: searchString };
    return this.userService.getUserGroups(request);
  }

  getSearchedUsers(search: string): void {
    this.getUsers(search).subscribe(
      res => {
        const userOptions = [];
        res.data.forEach(item => {
          const user = new SearchUserOptionsModel(item);
          userOptions.push(user);
        });
        this.users.next(userOptions);
      }
    );
  }

  getSearchedUserGroups(search: string): void {
    this.getUserGroups(search).subscribe(
      res => {
        const filteredData = res.data.filter(item => {
          // Filter out items with type 'Group' and status 'Inactive'
          if (item.isGroup === true && item.status === 'Inactive') {
            return false;
          }
          return (
            item.type === 'User' ||
            item.type === GroupTypeStringEnum.All ||
            item.type === GroupTypeStringEnum.CRMAssignment ||
            item.type === GroupTypeStringEnum.RepresentativeRole ||
            item.type === GroupTypeStringEnum.CRMandRepresentativeRole ||
            item.type === GroupTypeStringEnum.CRMandECM) &&
            (item.status === UserStatusTypeStringEnum.New ||
            item.status === UserStatusTypeStringEnum.Active);
        });

        const userOptions = filteredData.map(item => new SearchUserOptionsModel(item));
        this.users.next(userOptions);
      }
    );
  }

  getTaskTemplates(): void {
    const request: TaskTemplateRequest = {
      status: TaskTemplateStatus.Active,
      useType: [TaskTemplateUseTypeEnum[TaskTemplateUseTypeEnum.Task], TaskTemplateUseTypeEnum[TaskTemplateUseTypeEnum.All]],
      sortBy: 'name'
    };

    this.service.getTaskTemplates(request)
      .subscribe(taskTemplates => {
        this.taskTemplates.next(taskTemplates.data);
      });
  }

  getEmailTemplates(ids: string[]): void {
    if (ids && ids.length) {
      this.service.getEmailTemplates(ids)
        .subscribe(data => {
          this.emailTemplates.next(data);
        });
    }
  }

  getTaskListSearchedUserGroups(search: string): void {
    this.getUserGroups(search).subscribe(
      res => {
        const filteredData = res.data.filter(x => {
          // Filter out items with type 'Group' and status 'Inactive'
          if (x.isGroup === true && x.status === 'Inactive') {
            return false;
          }
          // Include items with types 'CRM Assignment', 'User', or 'All'
          return x.type === GroupTypeStringEnum.All || x.type === GroupTypeStringEnum.CRMAssignment ||
          x.type === GroupTypeStringEnum.RepresentativeRole || x.type === GroupTypeStringEnum.CRMandRepresentativeRole ||
          x.type === GroupTypeStringEnum.CRMandECM || x.type === GroupTypeStringEnum.ECMandRepresentativeRole;
        });

        const userOptions = filteredData.map(item => new SearchUserOptionsModel(item));
        this.users.next(userOptions);
      }
    );
  }
}
