<!--The content below is only a placeholder and can be replaced.-->

<ngx-spinner></ngx-spinner>

<router-outlet *ngIf="isOnline; else isOffline"></router-outlet>
<ng-template #isOffline>
  <div>
    <p class="offline-error">You are offline</p>
  </div>
</ng-template>
<div id="custom-backdrop"></div>