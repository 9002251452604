import { FormControl, ValidatorFn } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ApplicationsTypeEnum } from '@shared/constants/select-options.enums';
import { AosUrls } from '@core/constants';
import { saveAs } from 'file-saver';

/**
 *
 * @param condition boolean
 * @param validator ValidatorFn
 */
export function optionalValidator(condition: () => boolean, validator: ValidatorFn): ValidatorFn {
  return (control: FormControl) => {
    return control.parent && condition() ?
      validator(control) :
      null;
  };
}

export function createSearchParameters(params: any): HttpParams {
  let searchParams: HttpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const val = params[key];
      if (val != null) {
        searchParams = searchParams.set(key, val);
      }
    }
  }
  return searchParams;
}

export function openAosApplicationDetails(type: string, id: number): void {
  window.open(AosUrls.application(ApplicationsTypeEnum[type], id), '_blank');
}

export function addThemeCss(url): void {
  const head = document.head;
  const link = document.createElement('link');

  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;

  head.appendChild(link);
}

export function formatBytes(bytes, decimals = 2): any {
  if (bytes === 0) { return '0 Bytes'; }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function saveAsBlob(data: Blob, name: string, type?): void {
  const blob = new Blob([data], { type });
  saveAs(blob, name);
}


export function generateUniqueId(prefix = 'custom'): string {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substring(2);
  return prefix + '-' + dateString + '-' + randomness;
}

export function hasDuplicates(arr: any[], key: string): boolean {
  const seen = new Set();
  for (const item of arr) {
      if (seen.has(item[key])) {
          return true;
      }
      seen.add(item[key]);
  }
  return false;
}
