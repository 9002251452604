<div class="sm-font-container">
    <br>
    <form [formGroup]="form">

        <div fxLayout>
            <div fxFlex="100" class="left-col">
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Prospect type</mat-label>
                    <mat-select formControlName="prospectType">
                        <mat-option [value]="ProspectTypeEnum.Individual">
                            Individual
                        </mat-option>
                        <mat-option [value]="ProspectTypeEnum.Business">
                            Business
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout>
            <div fxFlex="100" class="right-col">
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Branch</mat-label>
                    <mat-select formControlName="branchId" [(value)]="defaultBranch && defaultBranch.value">
                        <mat-option *ngFor="let branch of userBranchOptions" [value]="branch.value">
                            {{branch.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual">
            <div fxFlex="100" class="left-col">
                <mat-form-field appearance="outline" [style.width.%]="95">
                    <mat-label>Lead Type</mat-label>
                    <mat-select formControlName="retailLead">
                        <mat-option *ngFor="let leadType of LeadTypeOptions"
                            [value]="leadType.value">{{leadType.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="100" class="left-col">
                <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Preferred method of contact</mat-label>
                    <mat-select formControlName="retailPreferredContact">
                        <mat-option *ngFor="let contact of PrefferedContactOptions"
                            [value]="contact.value">{{contact.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <div fxFlex="100" class="left-col">
                <mat-form-field appearance="outline" [style.width.%]="95">
                    <mat-label>Lead Type</mat-label>
                    <mat-select formControlName="businessLead">
                        <mat-option *ngFor="let leadType of LeadTypeOptions"
                            [value]="leadType.value">{{leadType.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="100" class="left-col">
                <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Preferred method of contact</mat-label>
                    <mat-select formControlName="businessPreferredContact">
                        <mat-option *ngFor="let contact of PrefferedContactOptions"
                            [value]="contact.value">{{contact.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <mat-divider></mat-divider>
        <div *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual" style="padding-bottom: 10px;">
            <br>
            Information
            <br>
        </div>
        <div *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business" style="padding-bottom: 10px;">
            <br>
            Business Information
            <br>
        </div>



        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual">
            <div fxFlex="20" class="left-col">
                <mat-form-field [style.width.%]="80" appearance="outline">
                    <mat-label>Mr/Mrs</mat-label>
                    <mat-select formControlName="retailSaluation">
                        <mat-option *ngFor="let saluation of SaluationOptions"
                            [value]="saluation.value">{{saluation.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="100" class="left-col">
                <mat-form-field [style.width.%]="95" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" formControlName="retailFirstName" min="3">
                </mat-form-field>
            </div>
            <div fxFlex="100" class="right-col">
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name" formControlName="retailLastName" min="3">
                </mat-form-field>
            </div>
        </div>

        <div fxLayout
            *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business || form.get('prospectType').value === ProspectTypeEnum.Individual">
            <div fxFlex="100" class="right-col" *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual">
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Nick Name</mat-label>
                    <input matInput placeholder="Nick Name" formControlName="retailNickName" min="3">
                </mat-form-field>
            </div>

            <div fxFlex="100" *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
                <br>
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Business Type</mat-label>
                    <mat-select formControlName="businessType" (selectionChange)="updateBusinessRoleOptions($event)">
                        <mat-option *ngFor="let businessType of BusinessTypeOptions"
                            value="{{businessType.value}}">{{businessType.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <div fxFlex="100" class="left-col">
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Business Name</mat-label>
                    <input matInput placeholder="Business Name" formControlName="businessName" min="3">
                </mat-form-field>
            </div>
        </div>

        <div fxLayout>
            <div fxFlex="100" class="left-col" *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual">
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input matInput placeholder="Email" formControlName="retailEmail" min="3">
                </mat-form-field>
            </div>

            <div fxFlex="100" class="left-col" *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Business Email</mat-label>
                    <input matInput placeholder="Business Email" formControlName="businessEmail" min="3">
                </mat-form-field>
            </div>

        </div>

        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual">
            <div fxFlex="100" class="right-col">
                <mat-form-field [style.width.%]="95" appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input matInput placeholder="Phone Number" formControlName="retailPhoneNumber" min="3">
                </mat-form-field>
            </div>
            <div fxFlex="100" class="right-col">
                <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Phone Type</mat-label>
                    <mat-select formControlName="retailPhone">
                        <mat-option *ngFor="let phoneType of PhoneTypeOptions"
                            [value]="phoneType.value">{{phoneType.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


      

        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <div fxFlex="100" class="left-col">
                <mat-form-field [style.width.%]="95" appearance="outline">
                    <mat-label>Business Phone Number</mat-label>
                    <input matInput placeholder="Business Phone Number" formControlName="businessPhoneNumber" min="3">
                </mat-form-field>
            </div>
            <div fxFlex="100" class="right-col">
                <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Phone Type</mat-label>
                    <mat-select formControlName="businessPhone">
                        <mat-option *ngFor="let phoneType of BusinessPhoneTypeOptions"
                            [value]="phoneType.value">{{phoneType.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <br>
        
          <div *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business || form.get('prospectType').value === ProspectTypeEnum.Individual">
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="10px">
                <cm-button (click)="setAddressAddingType('search')" [style.width.%]="50"
                    [color]="addressAddingType === 'search' ? 'primary' : 'secondary'">Address Search</cm-button>
                <cm-button (click)="setAddressAddingType('manually')" [style.width.%]="50"
                    [color]="addressAddingType === 'manually' ? 'primary' : 'secondary'">Enter Address
                    Manually</cm-button>
            </div>
         
                <div fxLayout="column" style="margin-top: 20px;">
                    <ng-container *ngIf="addressAddingType === 'search'; else addAddressManually">
                        <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">
                            <div fxFlex="50" class="right-col">
                                <mat-form-field appearance="outline" [style.width.%]="100">
                                    <mat-label>Address Type</mat-label>
                                    <ng-container *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business; else addressTypeDropdown">
                                        <input matInput [value]="'Business'" readonly>
                                    </ng-container>
                                    <ng-template #addressTypeDropdown>
                                        <mat-select formControlName="type">
                                            <mat-option *ngFor="let item of getAvailableTypeOptions(); trackBy: trackByFn" [value]="item.value">
                                                {{item.label}}
                                            </mat-option>
                                        </mat-select>
                                    </ng-template>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50" class="left-col">
                                <mat-form-field appearance="outline" [style.width.%]="100">
                                    <mat-label>Country</mat-label>
                                    <mat-select formControlName="country" (selectionChange)="selectionChange($event)">
                                        <mat-option *ngFor="let item of allCountries" [value]="item.value">
                                            {{item.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" >
                            <app-canada-post-address fxFlex="100" (addressFound)="addressFound($event)"></app-canada-post-address>
                        </div>
                    </ng-container>

           
                        <ng-template #addAddressManually>
                            <form fxLayoutGap="5px grid" fxLayout="column wrap" [formGroup]="form">
                                <div fxLayout="row wrap" fxLayoutGap="10px grid">
                                    <mat-form-field fxFlex="1 1 50" appearance="outline">
                                        <mat-label>Address Type</mat-label>
                                        <ng-container *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business; else manualAddressTypeDropdown">
                                            <input matInput value="Business" readonly>
                                             
                                        </ng-container>
                                        <ng-template #manualAddressTypeDropdown>
                                            <mat-select formControlName="type">
                                                <mat-option *ngFor="let item of getAvailableTypeOptions(); trackBy:trackByFn" [value]="item.value">
                                                    {{item.label}}
                                                </mat-option>
                                            </mat-select>
                                        </ng-template>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="1 1 50" appearance="outline">
                                        <mat-label>Country</mat-label>
                                        <mat-select [disabled]="condensedInternational" formControlName="country" (selectionChange)="selectionChange($event)">
                                            <mat-option *ngFor="let item of allCountries" [value]="item.value">
                                                {{item.label}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                
                        <div fxLayout="row wrap" fxLayoutGap="10px grid">
                            <mat-form-field *ngIf="!condensedInternational" fxFlex="1 1 50" appearance="outline">
                                <mat-label>Address Line 1</mat-label>
                                <input formControlName="addressLine1" matInput (input)="toggleAddressValidation()" />
                            </mat-form-field>
                            <mat-form-field *ngIf="!condensedInternational" fxFlex="1 1 50" appearance="outline">
                                <mat-label>Address Line 2</mat-label>
                                <input formControlName="addressLine2" matInput  />
                            </mat-form-field>
                        </div>
                
                        <div fxLayout="row wrap" fxLayoutGap="20px grid">
                            <mat-form-field *ngIf="condensedInternational" fxFlex="1 1 50" appearance="outline">
                                <mat-label>{{poBoxSelected ? 'P.O Box Number' : 'Street Number'}}</mat-label>
                                <input formControlName="streetNumber" maxlength="28" matInput />
                            </mat-form-field>
                            <mat-form-field *ngIf="!poBoxSelected && condensedInternational" fxFlex="1 1 50"
                                appearance="outline">
                                <mat-label>Apartment, Suite, Etc</mat-label>
                                <input formControlName="apartment" maxlength="28" matInput />
                            </mat-form-field>
                            <mat-form-field fxFlex="1 1 33" appearance="outline">
                                <mat-label>City</mat-label>
                                <input formControlName="city" maxlength="28" matInput (input)="toggleAddressValidation()" />
                            </mat-form-field>
                            <mat-form-field fxFlex="1 1 33" appearance="outline">
                                <mat-label>{{!condensedInternational ? 'State / Province / Area' : 'Province'}}</mat-label>
                                <mat-select *ngIf="!condensedInternational || checkStateValidation()"
                                    (selectionChange)="toggleAddressValidation()"
                                    formControlName="provinceId">
                                    <mat-option *ngFor="let item of filteredProvinces" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <input *ngIf="condensedInternational && !checkStateValidation()"
                                    formControlName="provinceName" matInput />
                            </mat-form-field>
                            <mat-form-field fxFlex="1 1 34" appearance="outline">
                                <mat-label>{{!condensedInternational ? 'Zip Code' : 'Postal Code'}}</mat-label>
                                <input formControlName="postalCode" (input)="toggleAddressValidation()" maxlength="7" matInput />
                            </mat-form-field>
                        </div>
                
                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between" fxLayoutGap="15px">
                            <mat-checkbox *ngIf="condensedInternational" formControlName="poBox" fxFlex="1 1 100px"
                                fxFlexAlign="center">P.O
                                Box address?</mat-checkbox>
                        </div>
                    </form>
                </ng-template>
                
            </div>
        </div>

        <div *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual">
            <div *ngIf="form.get('retailEmail').hasError('required') && form.get('retailEmail').touched || 
            form.get('retailPhoneNumber').hasError('required') && form.get('retailPhoneNumber').touched"
                class="validation-error-checkbox">
                You must enter the contact information that aligns with the Preferred Method of Contact.
            </div>
        </div>
        <div class="help" *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual || 
            form.get('prospectType').value === ProspectTypeEnum.Business" style="padding-bottom: 10px;">
            <br>
            Marketing Communications
            <div class="tooltip">?
                <span class="tooltiptext">Do you consent to {{cuName}} keeping your contact details for marketing
                    purposes?</span>
            </div>
            <br>
        </div>
        <div *ngIf="form.get('prospectType').value === ProspectTypeEnum.Individual">
            <mat-checkbox formControlName="retailConsent" fxFlexAlign="center">Consent for Marketing</mat-checkbox>
            <div *ngIf="form.get('retailConsent').invalid && form.get('retailConsent').touched"
                class="validation-error-checkbox">
                You must consent for marketing.
            </div>
        </div>

        <div *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <mat-checkbox formControlName="businessConsent" fxFlexAlign="center">Consent for Marketing</mat-checkbox>
            <div *ngIf="form.get('businessConsent').invalid && form.get('businessConsent').touched"
                class="validation-error-checkbox">
                You must consent for marketing.
            </div>
        </div>

        <div *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <div *ngIf="form.get('contactPhoneNumber').hasError('required') && form.get('contactPhoneNumber').touched || 
             form.get('businessPhone').hasError('required') && form.get('businessPhone').touched || 
             form.get('contactEmail').hasError('required') && form.get('contactEmail').touched || 
             form.get('businessEmail').hasError('required') && form.get('businessEmail').touched ||
             form.get('businessPhoneNumber').hasError('required') && form.get('businessPhoneNumber').touched"
                class="validation-error-checkbox">
                You must enter the contact information that aligns with the Preferred Method of Contact.
            </div>
        </div>
        <mat-divider *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business"></mat-divider>
        <br *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">

        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <div fxFlex="100" class="left-col">
                <mat-form-field appearance="outline" [style.width.%]="95">
                    <mat-label>Role Type</mat-label>
                    <mat-select formControlName="contactRole">
                        <mat-option *ngFor="let roleType of BusinessRoleTypeOptions"
                            [value]="roleType.value">{{roleType.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="100" class="right-col">
                <mat-form-field [style.width.%]="95" appearance="outline">
                    <mat-label>Nick Name</mat-label>
                    <input matInput placeholder="Nick Name" formControlName="contactNickName" min="3">
                </mat-form-field>
            </div>
        </div>

        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <div fxFlex="20" class="left-col">
                <mat-form-field [style.width.%]="80" appearance="outline">
                    <mat-label>Saluation</mat-label>
                    <mat-select formControlName="contactSaluation">
                        <mat-option *ngFor="let saluation of SaluationOptions"
                            [value]="saluation.value">{{saluation.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="100" class="left-col">
                <mat-form-field [style.width.%]="90" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="Contact First Name" formControlName="contactFirstName" min="3">
                </mat-form-field>
            </div>
            <div fxFlex="100" class="right-col">
                <mat-form-field [style.width.%]="90" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Contact Last Name" formControlName="contactLastName" min="3">
                </mat-form-field>
            </div>
        </div>

        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <div fxFlex="100" class="left-col">
                <mat-form-field [style.width.%]="100" appearance="outline">
                    <mat-label>Contact Email</mat-label>
                    <input matInput placeholder="Contact Email" formControlName="contactEmail" min="3">
                </mat-form-field>
            </div>
        </div>

        <div fxLayout *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
            <div fxFlex="100" class="left-col">
                <mat-form-field appearance="outline" [style.width.%]="95">
                    <mat-label>Phone Type</mat-label>
                    <mat-select formControlName="contactPhone">
                        <mat-option *ngFor="let phoneType of BusinessPhoneTypeOptions"
                            [value]="phoneType.value">{{phoneType.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="100" class="right-col" *ngIf="form.get('prospectType').value === ProspectTypeEnum.Business">
                <mat-form-field [style.width.%]="95" appearance="outline">
                    <mat-label>Contact Phone Number</mat-label>
                    <input matInput placeholder="Contact Phone Number" formControlName="contactPhoneNumber" min="3">
                </mat-form-field>
            </div>

        </div>
        <br>
        <div class="actions" fxLayoutAlign="end" fxLayoutGap="8px">
            <cm-button (click)="cancel($event)" color="secondary">Cancel</cm-button>
            <cm-button (click)="add()" [disabled]="!isFormValid">Add</cm-button>
        </div>
    </form>
</div>