import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskApiService, EmailTemplatesApiService } from '@core/services/api.services';
import { TaskTemplate } from '@shared/models/task-template';
import { SearchEmailTemplatesDto } from '@core/services/dto';

@Injectable({ providedIn: 'any' })

export class TaskTemplateModalDialogStateService {
  constructor(
    private service: TaskApiService,
    private emailService: EmailTemplatesApiService
  ) {

  }

  createTaskTemplate(taskTemplate: TaskTemplate): Observable<TaskTemplate> {
    return this.service.createTaskTemplate(taskTemplate);
  }

  getTaskTemplate(id: number): Observable<TaskTemplate> {
    return this.service.getTaskTemplate(id)
    .pipe(
      map(taskTemplateDto => new TaskTemplate(taskTemplateDto))
    );
  }

  updateTaskTemplate(id: number, taskTemplate: TaskTemplate): Observable<TaskTemplate> {
    return this.service.updateTaskTemplate(id, taskTemplate);
  }

  getEmailTemplates(ids: number[]): Observable<SearchEmailTemplatesDto> {
    return this.emailService.searchTemplates({includeIds: ids});
  }
}
