<div class="container">
  <div class="title">
    Link
  </div>
  <div fxLayout="row wrap"
       fxLayoutAlign="start center">
    <app-linked-instance *ngFor="let item of chosenLinks"
                         [instance]="item"
                         [readOnly]="readOnly"
                         (removeItem)="removeFromChosenLinks($event)"></app-linked-instance>
  </div>
  <div *ngIf="!readOnly"
       fxLayout="row wrap"
       class="links-container"
       [ngClass]="isLongLinksList ? 'long-list' : ''">
    <div *ngIf="checkLink('prospect')"
         class="link-item">
      <cm-button color="gray"
                 (click)="openLink(LinkType.prospect)"
                 active="{{currentLink === LinkType.member || 
                 currentLink === LinkType.prospect? 'active': ''}}">
       Link Member/Prospect
      </cm-button>
    </div>
    <div *ngIf="checkLink('member')"
         class="link-item">
      <cm-button color="gray"
                 (click)="openLink(LinkType.member)"
                 active="{{currentLink === LinkType.member ? 'active': ''}}">
                 Link Member
      </cm-button>
    </div>
    <div *appCheckPermission="permissionAccount">
      <div *ngIf="checkLink('account')"
           class="link-item">
        <cm-button color="gray"
                   (click)="isMemberLinked && openLink(LinkType.account)"
                   active="{{currentLink === LinkType.account ? 'active': ''}}"
                   [disabled]="!isMemberLinked"
                   [ngClass]="{'active': currentLink === LinkType.account}">
          Link Account
        </cm-button>
      </div>
    </div>

    <div *appCheckPermission="permissionTask">
      <div *ngIf="checkLink('task')"
           class="link-item">
        <cm-button color="gray"
                   (click)="isMemberLinked && openLink(LinkType.task)"
                   [disabled]="!isMemberLinked"
                   active="{{currentLink === LinkType.task ? 'active': ''}}">
          Link Task
        </cm-button>
      </div>
    </div>

    <div *appCheckPermission="permissionNote">
      <div *ngIf="checkLink('note')"
           class="link-item">
        <cm-button (click)="isMemberLinked && openLink(LinkType.note)"
                   [disabled]="!isMemberLinked"
                   active="{{currentLink === LinkType.note ? 'active': ''}}"
                   color="gray">
          Link Note
        </cm-button>
      </div>
    </div>

    <div *appCheckPermission="permissionConversation">
      <div *ngIf="checkLink('conversation')"
           class="link-item">
        <cm-button color="gray"
                   (click)="isMemberLinked && openLink(LinkType.conversation)"
                   [disabled]="!isMemberLinked"
                   active="{{currentLink === LinkType.conversation ? 'active': ''}}">
          Link Conversation
        </cm-button>
      </div>
    </div>

    <div *appCheckPermission="permissionSales">
      <div *ngIf="checkLink('sale')"
           class="link-item">
        <cm-button color="gray"
                   (click)="isMemberLinked && openLink(LinkType.sale)"
                   [disabled]="!isMemberLinked"
                   active="{{currentLink === LinkType.sale ? 'active': ''}}">
          Link Sale
        </cm-button>
      </div>
    </div>
  </div>

  <form class="example-form" [formGroup]="form">
    <app-input-autocomplete *ngIf="currentLink === LinkType.prospect"
                            formControlName="membersProspects"
                            placeholder="Enter Member/Prospect Number or Name"
                            [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                            [options]="membersProspects$ | async"
                            (clicked)="addInstance($event)">
    </app-input-autocomplete>

    <app-input-autocomplete *ngIf="currentLink === LinkType.member"
                            formControlName="members"
                            placeholder="Enter Member Number or Name"
                            [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                            [options]="members$ | async"
                            (clicked)="addInstance($event)">
    </app-input-autocomplete>

    <app-input-autocomplete *ngIf="currentLink === LinkType.account && isMemberLinked"
                            formControlName="accounts"
                            placeholder="Account's name"
                            [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                            [options]="accounts$ | async"
                            (clicked)="addInstance($event)">
    </app-input-autocomplete>
    <app-input-autocomplete *ngIf="currentLink === LinkType.task && isMemberLinked"
                            formControlName="tasks"
                            placeholder="Task description"
                            [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                            [options]="tasks$ | async"
                            (clicked)="addInstance($event)">
    </app-input-autocomplete>
    <app-input-autocomplete *ngIf="currentLink === LinkType.note && isMemberLinked"
                            [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                            [options]="notes$ | async"
                            formControlName="notes"
                            placeholder="Note description"
                            (clicked)="addInstance($event)">
    </app-input-autocomplete>
    <app-input-autocomplete *ngIf="currentLink === LinkType.conversation && isMemberLinked"
                            formControlName="conversations"
                            placeholder="Conversation subject"
                            [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                            [options]="conversations$ | async"
                            (clicked)="addInstance($event)">
    </app-input-autocomplete>
  </form>
</div>
