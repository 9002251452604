import { SiteDto } from '@core/services/dto';

export class SiteModel {
  id: string;
  latitude: number;
  longitude: number;
  name: string;
  location: string;

  constructor(dto: SiteDto) {
    for (const prop in dto) {
      if (dto.hasOwnProperty(prop)) {
        this[prop] = dto[prop];
      }
    }
    this.location = `lat=${dto.latitude}&lon=${dto.longitude}`;
  }
}
