import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProvinceModel } from '@shared/models';
import { CanadaPostAddressStateService } from '@shared/components/canada-post-address/canada-post-address.state.service';
import { InputAutocompleteComponent } from '@shared/components/input-autocomplete/input-autocomplete.component';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CanadaPostAddressModel } from '@shared/components/canada-post-address/models/canada-post-address.model';
import { CanadaPostAddressSearchModel } from '@shared/components/canada-post-address/models/canada-post-address-search.model';
import { BOOL_TYPE } from '@angular/compiler/src/output/output_ast';
import { AddressInfoComponent } from 'app/modules/member-profile/pages/member-info/address-info/address-info.component';
import { ProspectAddressInfoComponent } from 'app/modules/prospect-profile/pages/prospect-info/profile-info/prospect-address-info/prospect-address-info.component';

@Component({
  selector: 'app-canada-post-address',
  templateUrl: './canada-post-address.component.html',
  styleUrls: ['./canada-post-address.component.scss']
})
export class CanadaPostAddressComponent extends autoUnsubscribeMixin() implements OnInit {
  @Input() addressId: string;
  @Output() addressFound: EventEmitter<CanadaPostAddressModel> = new EventEmitter<CanadaPostAddressModel>();

  searchAddress: CanadaPostAddressSearchModel | string;
  foundAddresses$: Observable<CanadaPostAddressSearchModel[]>;
  foundAddressesFromCanadaPost$: Observable<CanadaPostAddressSearchModel[]>;

  private provinces: ProvinceModel[];
  private searchAddressStatus = false;
  private countryCodeByDefault = 'CA';


  @ViewChild(InputAutocompleteComponent) inputAutocompleteComponent: InputAutocompleteComponent;

  constructor(private canadaPostAddressStateService: CanadaPostAddressStateService) {
    super();

    this.foundAddressesFromCanadaPost$ = this.canadaPostAddressStateService.addresses$;
    this.foundAddresses$ = this.foundAddressesFromCanadaPost$;
  }

  ngOnInit(): void {
    this.canadaPostAddressStateService.getProvinces()
      .subscribe(res => {
        this.provinces = res.data;
      });

    if (!!this.addressId) {
      this.canadaPostAddressStateService.retrieveAddress(this.addressId, this.searchAddressStatus, this.countryCodeByDefault)
        .subscribe(addresses => {
          const addr = addresses[0];
          this.searchAddress = new CanadaPostAddressSearchModel(addr);

          this.foundAddresses$ = of([this.searchAddress]);
        });
    }

    this.canadaPostAddressStateService.countryCode.subscribe((data: any) => {
      this.countryCodeByDefault = data ;
    });
    this.canadaPostAddressStateService.getAutoComplete()
      .subscribe((result: any) => {
        this.searchAddressStatus = result;
      });
  }

  searchAddressChanged(event: CanadaPostAddressSearchModel | string): void {
    this.searchAddress = event;

    if (typeof event === 'string') {
      this.canadaPostAddressStateService.searchAddress(event, null, this.searchAddressStatus, this.countryCodeByDefault);
      this.foundAddresses$ = this.foundAddressesFromCanadaPost$;
    }
    else if (event === null) {
      this.foundAddresses$ = of([]);
    }
  }

  addressSelected(event: CanadaPostAddressSearchModel): void {
    if (this.needToRefineAddressSearch(event)) {
      this.canadaPostAddressStateService.searchAddress(null, event.value, this.searchAddressStatus, this.countryCodeByDefault);
      this.foundAddresses$ = this.foundAddressesFromCanadaPost$;
      this.foundAddresses$
        .pipe(take(1))
        .subscribe(() => {
          if (!!this.inputAutocompleteComponent?.matAutocompleteTrigger
            && !this.inputAutocompleteComponent?.matAutocompleteTrigger?.panelOpen) {
            this.inputAutocompleteComponent?.matAutocompleteTrigger?.openPanel();
          }
        });

    }
    else {
      this.canadaPostAddressStateService.retrieveAddress(event.value, this.searchAddressStatus, this.countryCodeByDefault)
        .subscribe(addresses => {
          const addr = addresses[0];
          const addrModelToEmit = new CanadaPostAddressModel(addr, this.provinces);
          this.addressFound.emit(addrModelToEmit);
        });

      this.searchAddress = event;
    }
  }

  needToRefineAddressSearch(obj: CanadaPostAddressSearchModel): boolean {
    return obj.next === 'Find';
  }
}
