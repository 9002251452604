<div fxLayout="row"
     class="filters">
  <i class="icon-search"></i>
  <div fxLayout="column"
       fxLayoutGap="13px"
       fxFlex="auto">
    <div *ngFor="let filter of filterRows"
         fxLayoutAlign="start"
         class="filter-row"
         fxLayout="row wrap"
         fxLayoutGap="13px grid">
      <ng-container #row>
      </ng-container>
    </div>
  </div>
</div>
