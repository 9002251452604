<app-email-template-list  *ngIf="selected && selected.length" [mode]="'edit'" [templates]="selected"
                          (delete)="remove($event)"></app-email-template-list>

<form [formGroup]="form">
  <app-input-autocomplete formControlName="emailTemplate"
                          placeholder="Email Template search"
                          [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                          [options]="templates$ | async"
                          (clicked)="addInstance($event)">
  </app-input-autocomplete>
</form>
