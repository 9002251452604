import { map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EmailTemplatesApiService } from '@core/services/api.services';
import { SearchEmailTemplatesOptionsModel } from '@shared/models';
import { EmailTemplateSearchRequestDto } from '@shared/models/requests/email-template-search.request.dto';

@Injectable({
  providedIn: 'root'
})
export class AddEmailTemplateStateService {
  private templates: Subject<SearchEmailTemplatesOptionsModel[]> = new Subject<SearchEmailTemplatesOptionsModel[]>();

  constructor(private service: EmailTemplatesApiService) {
  }

  templates$(): Observable<SearchEmailTemplatesOptionsModel[]> {
    return this.templates.asObservable();
  }

  getTemplates(name: string, excludeIds: number[]): void {
    const request: EmailTemplateSearchRequestDto = {
      type: 'CRM',
      excludeIds,
      name,
      pageSize: 3,
      sortBy: 'name',
      sort: 'asc'
    };


    this.service.searchTemplates(request)
      .pipe(map(res => res.data.map(item => new SearchEmailTemplatesOptionsModel(item))))
      .subscribe(data => this.templates.next(data));
  }
}

