import { LinkModel } from '@shared/models/link.model';
import { SearchMemberProspectOptionsModel } from './search.member.prospect.options.model';

export class ChosenLinkModel {
  label: string;
  ref: string;
  type: string;
  value: string;
  links?: LinkModel[];
  disabled: boolean;
  isProspectInfo?: boolean;
  accountNumber?: string;
}
