import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404PageComponent } from '@shared/pages/error404-page/error404-page.component';
import { AppConfig } from '@config/app.config';
import { AuthGuardService } from '@core/guards';
import { PlatformLayoutComponent } from '@core/layouts';
import { PermissionGuardService } from '@core/guards/permission-guard.service';
import { PermissionStringEnum } from '@shared/enums';
import { EcmUsageGuardService } from '@core/guards/ecm-usage-guard.service';

const routes: Routes = [
  {
    path: '',
    component: PlatformLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: AppConfig.routes.dashboard,
        pathMatch: 'full',
      },
      {
        path: AppConfig.routes.dashboard,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.memberProfile.main,
        children: [
          {
            path: ':id',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewMembers },
            loadChildren: () =>
              import('./modules/member-profile/member-profile.module').then(
                (m) => m.MemberProfileModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.prospectProfile.main,
        children: [
          {
            path: ':id',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewMembers },
            loadChildren: () =>
              import('./modules/prospect-profile/prospect-profile.module').then(
                (m) => m.ProspectProfileModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.conversations,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewConversations },
            loadChildren: () =>
              import('./modules/conversations/conversations.module').then(
                (m) => m.ConversationsModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.importConversation,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewConversations },
            loadChildren: () =>
              import(
                './modules/customer-management/import-conversation/import-conversation.module'
              ).then((m) => m.ImportConversationModule),
          },
        ],
      },
      {
        path: AppConfig.routes.reports.root,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/reports/reports.module').then(
                (m) => m.ReportsModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.opportunities,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewConversations },
            loadChildren: () =>
              import('./modules/conversations/conversations.module').then(
                (m) => m.ConversationsModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.importOpportunities,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewConversations },
            loadChildren: () =>
              import(
                './modules/customer-management/import-opportunities/import-opportunities.module'
              ).then((m) => m.ImportOpportunitiesModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.assignedApplications,
        children: [
          {
            path: '',
            loadChildren: () =>
              import(
                './modules/customer-management/assigned-applications/assigned-applications.module'
              ).then((m) => m.AssignedApplicationsModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.recentApplications,
        children: [
          {
            path: '',
            loadChildren: () =>
              import(
                './modules/customer-management/recent-applications/recent-applications.module'
              ).then((m) => m.RecentApplicationsModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.sales,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: {
              permission: [
                PermissionStringEnum.ViewAllSales,
                PermissionStringEnum.ViewMySales,
              ],
            },
            loadChildren: () =>
              import('./modules/customer-management/sales/sales.module').then(
                (m) => m.SalesModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.concerns,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewConversations },
            loadChildren: () =>
              import('./modules/conversations/conversations.module').then(
                (m) => m.ConversationsModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.importConcern,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewConversations },
            loadChildren: () =>
              import(
                './modules/customer-management/import-concern/import-concern.module'
              ).then((m) => m.ImportConcernModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.tasks,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewAllTasks },
            loadChildren: () =>
              import('./modules/customer-management/tasks/tasks.module').then(
                (m) => m.CustomerTasksModule
              ),
          },
          {
            path: `:id`,
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewAllTasks },
            loadChildren: () =>
              import(
                './modules/customer-management/common-task-details/common-task-details.module'
              ).then((m) => m.CommonTaskDetailsModule),
          },
          {
            path: `:id/edit`,
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.EditTask },
            loadChildren: () =>
              import(
                './modules/customer-management/common-task-details/common-task-details.module'
              ).then((m) => m.CommonTaskDetailsModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.recentProfiles,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewMembers },
            loadChildren: () =>
              import(
                './modules/customer-management/recent-profiles/recent-profiles.module'
              ).then((m) => m.RecentProfilesModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.announcements,
        children: [
          {
            path: '',
            loadChildren: () =>
              import(
                './shared/components/announcements/user-announcements/user-announcements.module'
              ).then((m) => m.UserAnnouncementsModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.search,
        canActivate: [PermissionGuardService],
        data: { permission: PermissionStringEnum.ViewMembers },
        loadChildren: () =>
          import('./modules/customer-management/search/search.module').then(
            (m) => m.SearchModule
          ),
      },
      {
        path: AppConfig.routes.contentManagement.taskTemplates,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ManageTaskTemplates },
            loadChildren: () =>
              import(
                './modules/content-management/task-templates/task-templates.module'
              ).then((m) => m.TaskTemplatesModule),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.campaignManagement,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ManageCampaigns },
            loadChildren: () =>
              import(
                './modules/content-management/campaign-management/campaign-management.module'
              ).then((m) => m.CampaignManagementModule),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.tagManagement,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService, EcmUsageGuardService],
            data: { permission: PermissionStringEnum.ManageTags },
            loadChildren: () =>
              import(
                './modules/content-management/tag-management/tag-management.module'
              ).then((m) => m.TagManagementModule),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.announcements,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: {
              permission: [
                PermissionStringEnum.ManageAnnouncements,
                PermissionStringEnum.AosAdmin,
              ],
            },
            loadChildren: () =>
              import(
                './shared/components/announcements/admin-announcements/admin-announcements.module'
              ).then((m) => m.AdminAnnouncementsModule),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.ecm,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService, EcmUsageGuardService],
            data: {
              permission: [
                PermissionStringEnum.ECMAdmin,
                PermissionStringEnum.ECMUser,
              ],
            },
            loadChildren: () =>
              import('./modules/content-management/ecm/ecm.module').then(
                (m) => m.EcmModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.workflowTemplates,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ManageWorkflowTemplates },
            loadChildren: () =>
              import(
                './modules/content-management/workflow-templates/workflow-templates.module'
              ).then((m) => m.WorkflowTemplatesModule),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.privateLinks,
        children: [
          {
            path: '',
            loadChildren: () =>
              import(
                './modules/content-management/links/private-links/private-links.module'
              ).then((m) => m.PrivateLinksModule),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.publicLinks,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: {
              permission: [
                PermissionStringEnum.ManagePublicLinks,
                PermissionStringEnum.AosAdmin,
              ],
            },
            loadChildren: () =>
              import(
                './modules/content-management/links/public-links/public-links.module'
              ).then((m) => m.PublicLinksModule),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.emailTemplates,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ManageEmailTemplates },
            loadChildren: () =>
              import(
                './modules/content-management/email-templates-page/email-templates-page.module'
              ).then((m) => m.EmailTemplatesPageModule),
          },
        ],
      },
      {
        path: AppConfig.routes.setup.themeBuilder,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/setup/theme-builder/theme-builder.module').then(
                (m) => m.ThemeBuilderModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.setup.userManagement,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ManageUsers },
            loadChildren: () =>
              import(
                './modules/setup/user-management/user-management.module'
              ).then((m) => m.UserManagementModule),
          },
        ],
      },
      {
        path: AppConfig.routes.setup.userGroupManagement,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService, EcmUsageGuardService],
            data: { permission: PermissionStringEnum.ManageUsers },
            loadChildren: () =>
              import(
                './modules/setup/user-group-management/user-group-management.module'
              ).then((m) => m.UserGroupManagementModule),
          },
        ],
      },
      {
        path: AppConfig.routes.versionings,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/versioning/versioning.module').then(
                (m) => m.VersioningModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.contentManagement.offerTemplates,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ManageOfferTemplates },
            loadChildren: () =>
              import(
                './modules/content-management/offer-templates/offer-templates.module'
              ).then((m) => m.OfferTemplatesModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.importoffers,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.AddOffer },
            loadChildren: () =>
              import(
                './modules/customer-management/import-offers/import-offers.module'
              ).then((m) => m.ImportOffersModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.importoffersdetail,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewOffer },
            loadChildren: () =>
              import(
                './modules/customer-management/import-offers/import-offers-details/import-offers-details.module'
              ).then((m) => m.ImportOffersDetailsModule),
          },
        ],
      },
      {
        path: AppConfig.routes.memberManagement.offers,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: { permission: PermissionStringEnum.ViewOffer },
            loadChildren: () =>
              import('./modules/customer-management/offers/offers.module').then(
                (m) => m.OffersModule
              ),
          },
        ],
      },
      {
        path: AppConfig.routes.setup.approvelModels,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: {
              permission: PermissionStringEnum.AosAdmin,
            },
            loadChildren: () =>
              import(
                './modules/lending-decisioning-management/approval-models/approval-models.module'
              ).then((m) => m.ApprovalModelsModule),
          },
        ],
      },
      {
        path: AppConfig.routes.setup.rateModels,
        children: [
          {
            path: '',
            canActivate: [PermissionGuardService],
            data: {
              permission: PermissionStringEnum.AosAdmin,
            },
            loadChildren: () =>
              import(
                './modules/lending-decisioning-management/rate-models/rate-models.module'
              ).then((m) => m.RateModelsModule),
          },
        ],
      },
    ],
  },
  {
    path: AppConfig.routes.error404,
    component: Error404PageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
