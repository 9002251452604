<ng-container [ngSwitch]="tempName">
  <ng-container *ngSwitchCase="'one'">
    <div fxLayout fxLayoutAlign="start center">
      <div *ngIf="!isBusinessAccount" class="icon" fxLayout fxLayoutAlign="center center">{{initials}}</div>
      <div *ngIf="isBusinessAccount"  class="icon" fxLayout fxLayoutAlign="center center"><i class="icon-bag"></i></div>
      <a [class.readOnly]="readOnly" (click)="goToProfile(member.ref, $event); $event.stopPropagation()">
        {{getFullName(member)}}
      </a>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'many'">
    <div fxLayout fxLayoutAlign="start center" (mouseenter)="showList()" (mouseleave)="hideList()">
      <div class="group-icon"><i class="icon-group"></i></div>
      <a (click)="toggleList($event)" class="toggled-member-name">{{getFullName(member)}}</a>,
      <div>
        <span class="ellipsis" (click)="toggleList($event)">...</span>
        <div class="list-container" (mouseenter)="showList()" (mouseleave)="hideList()">
          <ul class="list shadow" *ngIf="show">
            <li class="list-item" *ngFor="let member of members">
              <a [class.readOnly]="readOnly" (click)="goToProfile(member.ref, $event)">
                {{getFullName(member)}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'zero'">
    <div fxLayout fxLayoutAlign="start center">
      <div class="icon" fxLayout fxLayoutAlign="center center"></div>
      <span>N/A</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'recent'">
    <div fxLayout fxLayoutAlign="start center" class="member">
      <div *ngIf="!isBusinessAccount && memberProfile.isProspectInfo === false" class="icon" fxLayout fxLayoutAlign="center center">{{iconGeneratorRecentProfile(memberProfile)}}</div>
      <div *ngIf="isBusinessAccount && memberProfile.isProspectInfo === false" class="icon" fxLayout fxLayoutAlign="center center"><i class="icon-bag"></i></div>
      <div *ngIf="memberProfile.isProspectInfo === true && memberProfile.memberType== 'Business'" class="icon" fxLayout fxLayoutAlign="center center" style="background-color: lightcyan;"><i class="icon-bag" style="color: green;"></i></div>
      <div *ngIf="memberProfile.isProspectInfo === true && memberProfile.memberType== 'Individual'" class="icon" fxLayout fxLayoutAlign="center center"  style="background-color: lightcyan;"><i class="icon-member"  style="color: green;" ></i></div>

      
      <div class="member-name-container">
        <a *ngIf="!memberProfile.isProspectInfo" [class.readOnly]="readOnly" class="member-name" routerLink="/{{links}}/{{memberProfile.memberId}}">
          {{getFullNameRecentProfile(memberProfile)}}
        </a>
        <a *ngIf="memberProfile.isProspectInfo" [class.readOnly]="readOnly" class="member-name" routerLink="/{{prospectlink}}/{{memberProfile.memberId}}">
          {{getFullNameRecentProfile(memberProfile)}}
        </a>
        <span *ngIf="isMemberNumber" class="id">{{memberProfile.memberNumber}}</span>
      </div>
    </div>
  </ng-container>
</ng-container>
