import { SearchMemberProspectOptionsModel } from './search.member.prospect.options.model';

export class MemberProspectInstancesModel {
  ref: string;
  type: string;
  memberNumber: string;
  isProspectInfo: boolean;

  constructor(dto: SearchMemberProspectOptionsModel) {
    this.ref = dto?.ref;
    this.type = dto?.type;

    this.memberNumber = dto?.memberNumber;
    this.isProspectInfo = dto?.isProspectInfo;
  }
}
